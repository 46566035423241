import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/presse-aus-liebe-zum-bier.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`

const PressePage = () => {
  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Aus Liebe zum Bier: ERDINGER Brauhaus auf der INTERNORGA – freilich jetzt auch alkoholfrei
              </Subtitle>
              <ContentText>
                <p>
                  „Internorga“ – 14. bis 18. März 2025<br />
                  ERDINGER führt die Besucher der diesjährigen „Internorga“ in Hamburg auf 
                  die Genussgipfel bayerischer Hellbier-Braukunst. Ganz neu im Gepäck: ERDINGER Brauhaus Helles 
                  Alkoholfrei – freilich jetzt auch alkoholfrei! Bayerisch-lässig präsentiert sich ERDINGER Brauhaus auf 
                  50 m2 in Halle B1.OG, Stand 310. Hier schenkt die Privatbrauerei ihre untergärigen Spezialitäten im 
                  entspannten Bar-Flair aus.
                </p>
                <p>
                  Süffig mild, mit bester Braumeister-Qualität und strikt nach dem Bayerischen Reinheitsgebot von 1516 
                  gebraut, ist ERDINGER Brauhaus Helles Schluck für Schluck ein Genuss. Wer es eher etwas fruchtiger 
                  mag, der lässt sich den perfekten fifty-fifty Mix aus frischer „Zitrone“ und süffigem Hellen beim 
                  ERDINGER Brauhaus NaturRadler schmecken. Und das neue ERDINGER Brauhaus Helles Alkoholfrei 
                  führt Hellbier-Liebhaber auf höchste Genussgipfel. Es begeistert mit frischer Süffigkeit, einer 
                  harmonischen Hopfennote und vollem Biergeschmack. Das muss man einfach probieren! 
                </p>
                <p>
                  Was natürlich nicht fehlen darf, ist die mittlerweile schon legendäre ERDINGER Brauhaus Beer Pong
                  Arena. Als offizieller Titelsponsor der BPong-Bundesliga baut ERDINGER wieder sein professionelles 
                  Beer Pong-Set-up mit zwei Profi-Tischen, Cups und Bällen auf. Hier können Besucher ihre 
                  Treffsicherheit testen, sich spannende Matches liefern und einfach eine gute Zeit haben. 
                  Vorbeischauen, mitspielen und die Hellbiere von ERDINGER genießen!
                </p>
                <p>
                  Für nähere Informationen steht das sympathische ERDINGER Messeteam vor Ort am Stand gerne zur 
                  Verfügung.
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <img src={pressePreviewImage} alt="Aus Liebe zum Bier: ERDINGER Brauhaus auf der INTERNORGA – freilich jetzt auch alkoholfrei" />
                </div>
              </ContentImageWrapper>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
